@mixin Store {
	.store {
		width: 100%;
		overflow: hidden;
		&.store-open--false {
			display: none;
		}
		.store__img {
			width: 100%;

			max-width: 1080px;
			margin: 0 auto;
			img {
				max-width: 100%;
			}
		}

	}
	.store__options {
		text-align: center;
		$w: 312px;
		margin-bottom: 128px;
		.store__option {
			width: $w;
			margin: 4px 8px;
			background: black;
			color: white;
			transform: scale(1) translateX(0) translateY(0);
		  	padding: 8px;

			.nft-thumb {
				display: block;
				width: $w/2;
				height: $w/2;
				margin: 0 auto;
				transform: scale(1) translateX(0) translateY(0);
				transition: 0.2s transform ease-in-out;
			}
			span {
				display: block;
				&.smaller {
					font-size: 14px;
					color: #aeaeae;
				}
			}
			&:hover {
				background: black;
				color: white;
				transform: scale(1) translateX(0) translateY(0);
				.nft-thumb {
					transform: scale(1.1) translateX(0) translateY(0);
				}
			}
		}
	}
}