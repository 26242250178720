@use "src/_theme.scss";
@use "src/_utils.scss";
@mixin Btn {

$buttonBG: #cfd2bf;
$darkPoint: -50%;
$calcFontColor: adjust-color(adjust-hue($buttonBG, 180deg), $lightness: $darkPoint);

button {
	@include utils.buttonStyling;
	@include utils.buttonSizing(24px);
	@include theme.altFont;
	
}
}