@use "src/_btn.scss";
@use "src/_theme.scss";
@use "src/_controls.scss";
@use "src/_inv.scss";
@use "src/_store.scss";
@use "src/_utils.scss";
@use "src/_bf.scss";

$red: #FF4A4A;
$rorange: #FF6A4D;
$orange: #FF9052;
$yorange: #FFB655;
$yellow: #FFD757;
body {
  @include theme.textFont;
  background: black;
}
strong, button, input {
  @include theme.altFont;
}

@include btn.Btn;
@include controls.Controls;
@include inv.Inv;
@include store.Store;

button.mmbtn {
  position: fixed;
  top: 16px;
  right: 16px;
  font-family: 'Inter', sans-serif;
  text-transform: uppercase;
  font-size: 16px;
}
.prepresale {
  display: none;
  color: white;
  &--true {
    display: block;
  }
}
.core {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  text-align: center;
  $inputH: 41px;
  $like8px: 8px;
  color: white;
  @include theme.mainFont;
  input {
    display: inline-block;
    outline: none;
    border: 1px solid rgba(theme.$borderColor, 0.5);
    background: black;
    color: theme.$borderColor;
    padding: 0 $like8px;
    
    @include theme.mainFont;

    filter: drop-shadow(2px 2px 2px black);
    margin-right: 8px;

    $h: $inputH;
    height: $h;
    font-size: $inputH*2/3;
    line-height: $inputH;
    box-sizing: border-box;

    cursor: pointer;
    transform: scale(1);
    border-radius: $like8px/2;


  }
  display: none;
  &--true {
    display: block;
  }
}
$time: 4s;
.presale-prompt {
  z-index: 99;
  position: relative;
  .pp__box {
    font-size: 24px;
  }
  transform: scale(1);
  transition: transform $time/2 ease-in-out, opacity $time/2 ease-in-out;
  opacity: 1;
  &--true {
    transform: scale(0);
    opacity: 0;
  }
}

.pp__text {
  p {
    margin-top: 0;
    margin-bottom: 0;
  }
}
.pp__input {
  p {
    margin-top: 6px;
    margin-bottom: 6px;
  }
}
.famgif {
  $s: 420px;
  position: fixed;
  bottom: 16px;
  left: 16px;
  height: $s;
  width: $s;
  img {
    height: $s;
    width: $s;
  }
  z-index: 1;
  opacity: 1;
  transition: opacity $time ease-in-out;
  &--true{
    opacity: 0;
  }
}
.surprise {
  font-weight: 400;
  cursor: pointer;

  &--true {
    color: orange;
  }
}
.door {

  transform:scale(1);
  opacity: 1;
  transition: transform $time ease-in-out, opacity $time ease-in-out;
  transform-origin: top center;
  &--true {
    transform: scale(4);
    opacity: 0;
  }
}
.dust {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0; 
  right: 0;
  z-index: 999;
  background: black;
  opacity: 0;
  transform: scale(0);
  transition: opacity $time ease-in-out $time, 
              transform $time ease-in-out;
  text-align: center;
  img {
    max-width: 100%;
    max-height: 100vh;
    margin: 0 auto;
  }

  &--true {
    opacity: 1;
    transform: scale(1);
  }
}

@media (max-width: 500px) {
  .door, .famgif {
    display: none;
  }
  .pp__box{
    margin-top: 128px;
  }
  .core {
    padding: 16px;
    box-sizing: border-box;
  }
  .pp__submit {
    margin-top: 8px;
    width: 60%;
  }
  .pp__input {
    input {
      width: 100%;
      text-align: center;
    }
  }
}


.period {
  cursor: pointer;
  &--true {
    color: orange;
  }
}
.secrets {
  position: fixed;
  display: none;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  color: white;
  background: rgba(0,0,0,0.8);
  padding-top: 10vh;
  p {
    width: 50vw;
    text-align: left;
    margin: 5px auto;
    font-size: 3.14vh;
    font-weight: 100;
    strong {
      font-weight: 600;
    }
    input {
      background: none;
      border: none;
      border-bottom: 1px solid white;
      outline: none;
      color: white;
      font-size: 3.14vh;
    }
  }


  &--true {
    display: block;
  }
}

button.btn-inventory, button.btn-inventory--1155 {
	position: fixed;
	bottom: 0;
	left: 16px;
  background: none;
  &.btn-inventory-open--true {
    
    color: $orange;
  }
 

  border: none;
  border-radius: 0;
  border-left: 1px solid white;
  font-family: 'Inter', sans-serif;
  text-transform: uppercase;

  padding: 6px 12px 14px 12px;
  transition: 0.2s padding-bottom ease-in-out, 0.2s background ease-in-out;
  &:hover {
    padding-bottom: 26px;
    transform: none;
    background: black;
    color: $yellow;
  }
}

button.btn-inventory--1155 {
    position: fixed;
    
    left: 220px;
    &.btn-inventory-open--true {
      
      color: $orange;
    }

  }


.dialog {
	max-width: 800px;
	margin: 70px auto 70px;
	&--false {
		display: none;
	}
	&__text {
		color: white;
		font-size: 18px;
		margin-left: 16px;
		max-width: calc(800px - 256px - 16px);
		display: inline-block;
    &--full {
      max-width: 100%;
    }
	}
	&__option {
		display: block;
		width: 100%;
    &.dialog__option--DLkey.dialog__option--3 {
      display: none;
      &.ifThree--3 {
        display: block;
      }
    }
	}
	&__option + &__option {
		margin-top: 16px;
	}
	&__speaker {
		border-radius: 8px;
		filter: drop-shadow(0 0 4px white); 
		height: 256px;
		width: 256px;
		overflow: hidden;
		display: inline-block;
		img {
			height: 256px;
			width: 256px;
		}
    &--full {
      width: 100%;
      height: auto;
      filter: drop-shadow(0 0 0 white); 
      border-radius: 0px;
      img {
        width: 100%;
        height: auto;
      }
    }
	}
	&__active {
		border-bottom: 1px solid white;
		margin-bottom: 32px;
	}
  &__options {
    &--false {
      display: none;
    }
  }
	
}
// ---------------------------------------------------------------------------------------
// Input Overlay --------------------------------------------------------------------------

$inputOverlayButtonSize: 20px;
$inputOverlayButtonHeight: 7*$inputOverlayButtonSize/4;
$inputOverlayGutter: 16px;
.btn {
  
  & + .btn {
    margin-left: 8px;
  }
}
.theInput {
  &.TOGGLE__theInput--false {
    display: none;
  }
  &.TOGGLE__theInput--true {
    display: block;
  }

  $w:1080px;

  z-index: 12;
  position: fixed;
  text-align: center;
  background: rgba(0,0,0,0.9);
  top: 0;
  left: 0;
  right: 0; 
  bottom: 0;
  padding: 16px;
  box-sizing: border-box;

  $inputH: $w/6.5;
  $like8px: $w/39;
  input {
    display: block;
     outline: none;
    border: min($like8px/4,2px) solid rgba(theme.$borderColor, 0.5);
    background: black;
    color: theme.$borderColor;
    padding: 0 $like8px;
    width: 100%;
    max-width: 640px;
    @include theme.mainFont;

    filter: drop-shadow(2px 2px 2px black);
    

    $h: $inputH;
    height: $h;
    font-size: $inputH*2/3;
    line-height: $inputH;
    box-sizing: border-box;

    margin: calc(50vh - #{$h}/2) auto;
    cursor: pointer;
    transform: scale(1);
    border-radius: $like8px/2;

  }

  .btn--max {
    position: absolute;
    top: calc(50vh - #{$inputH}/2 + #{$inputOverlayButtonHeight} + 40px);
    right: calc(50vw - 640px/2 + 16px);
  }
  .btn--back {
    position: absolute;
    top: 64px;
    left: calc(50vw - 640px/2);
    width: calc(100vw - 32px);
    max-width: 640px;
  }

  .btn--approve{
    position: absolute;
    top: calc(50vh + #{$inputH}/2 + #{$inputOverlayGutter} + 16px);
    left: calc(50vw - 640px/2);
    width: calc(100vw - 32px);
    max-width: 640px;
   
    margin: 0;
    box-sizing:border-box;
  }

  .btn--deposit, .btn--withdraw {
    position: absolute;
    top: calc(50vh + 24px + #{$inputH}/2 +  #{$inputOverlayGutter}*2 + #{$inputOverlayButtonHeight});
    left: calc(50vw - 640px/2);
    width: calc(100vw - 32px);
    max-width: 640px;
    margin: 0;
    box-sizing:border-box;

  }

  &__balance, &__poolbalance  {
    position: absolute;
    top: calc(50vh - #{$inputH} + #{$inputOverlayGutter}/2);
    width: 100%;
    color: theme.$borderColor;
    font-size: $inputOverlayButtonSize;

  }

  &__allowance {
    position: absolute;
    top: calc(50vh - #{$inputH} - #{$inputOverlayGutter}/2 - #{$inputOverlayButtonSize});
    width: 100%;
    color: theme.$borderColor;
    font-size: $inputOverlayButtonSize;
  }


 &.INTENT__theInput--add {
    .btn--approve, .btn--deposit {
      display: block;
    }
    .btn--withdraw {
      display: none;
    }
    .theInput__poolbalance {
      display: block;
      top: calc(50vh - #{$inputH} + #{$inputOverlayGutter}/2 + #{$inputOverlayButtonSize}*2);
    }
    .theInput__balance, .theInput__allowance {
      display: block;
    }
  }
  &.INTENT__theInput--remove {
    .btn--deposit {
      display: none;
    }
    .btn--approve, .btn--withdraw {
      display: block;
    }
    .theInput__poolbalance {
      top: calc(50vh - #{$inputH} + #{$inputOverlayGutter}/2 + #{$inputOverlayButtonSize}*2);
      display: block;
    }
    .theInput__balance, .theInput__allowance {
      display: block;
    }
  }
  .input-inner {
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
    position: relative;
  }
}


.pool {
	padding: 16px;
	text-align: center;
	color: white;
	h2 {
		margin-top: 128px;
		color: white;
		margin-left: 16px;
	}
	&__manage, &__harvest {
		height: 384px;
		width: 320px;
		display: inline-block;
		border: 1px solid white;
		border-radius: 8px;
		margin: 16px;
		vertical-align: top;
	}
	&-open--false {
		display: none;
	}
	&__token {
		position: relative;
		$r: 64px;
		height: 2*$r;
		width:  2*$r;
		overflow: hidden;
		border-radius: $r;
		margin: 48px auto;
		img {
			height: 2*$r;
			width: 2*$r;
		}
		img + img {
			position: absolute;
			height: $r;
			width: $r;
			right: $r/7;
			bottom: $r/6;
			filter: drop-shadow(0 0 4px #6348B1);

		}
		filter: drop-shadow(0 0 4px white);

	}
	.pool__btn {
		& + .pool__btn {
			margin-left: 16px;
		}
	}
	&__manage {
		.pool__btn {
			width: 52px;

		}
	}
	strong {
		margin-bottom: 32px;
		display: block;
		font-size: 20px;
	}


}

.pending {
	display: none;
	&--true {
		display: block;
	}
	position: fixed;
	top: 0;
	height: 64px;
	font-size: 24px;
	line-height: 64px;
	left: 0;
	width: 100%;
	text-align: center;
	color: black;
	background: white;
	z-index: 999;
}

.start-over {
	position: fixed;
	top: 16px;
	left: 0;
  transform: translateX(0);
  &--begin {
    top: 72px;
  }
  &--tools {
    top: 128px;
  }
  &--portfolio {
    top: 184px;
  }
  &--bf {
    top: 240px;
  }
  border: none;
  border-radius: 0;
  border-bottom: 1px solid white;
  font-family: 'Inter', sans-serif;
  text-transform: uppercase;

  padding: 6px 16px 6px 28px;
  transition: 0.2s padding-left ease-in-out, 0.2s background ease-in-out;
  &:hover {
    padding-left: 40px;
    transform: none;
    background: black;
    color: $yellow;
  }
}

.questReady--false {
  .start-over {
    transform: translateX(-200px);

  }
}



.modal {
  position: fixed;

  top: 30vh;
  bottom: 30vh;
  left: 20vw;
  right: 20vw;

  border: 1px solid white;
  background: black;
  color: white;
  text-align: center;

  &--false {
    display: none;
  }
  padding: 16px;
  img {
    height: 10vh;
    width: 10vh;
  }

}
$minhTools: 400px;
.tools {
  top: 20vh;
  bottom: 20vh;
  max-width: 800px;
  left: calc((100vw - 800px)/2);
  right: auto;
  box-sizing: border-box;

  overflow-y: scroll;
  padding: 32px;
  @include utils.scrolly;
  &--false {
    display: none;
  }
  .tools__box {
    font-family: 'Inter', sans-serif;
    h3 {
      font-size: 40px;
      margin: 16px auto 8px;
      //border-bottom: 1px dashed white;
      padding-bottom: 16px;
      color: $yorange;
    }
    button {
      margin: 8px;
    }
    padding-bottom: 16px;
    border-bottom: 4px solid white;
    margin-bottom: 32px;

    .tools__box-sub {
      position: relative;

      h4 {
        font-size: 32px;
        margin-bottom: 16px;
        margin-top: 16px;
        color: $rorange;
      }
      //border-bottom: 1px dashed white;
      padding-bottom: 8px;
      margin-bottom: 16px;
      input {
        box-sizing: border-box;
        font-size: 24px;
        width: 64px;
        text-align: center;
      }
      a {
        color: $yellow;
        &:visited {
          color: $yellow;
        }
      }
      p {
        span {
          color: #5a5a5a;
        }
      }

      p.market-price {
        font-size: 24px;
        margin-top: 12px;
        margin-bottom: 12px;
        strong {
          font-weight: 400;
          color: $orange;
        }
      }
      $rowH: 48px;
      .box-row {
        $wl: 256px;
        $priceH: 48px;
        $priceFS: 24px;
        $padL: 8px;
        position: absolute;
        height: $rowH;
        width: 100%;
        border: 1px solid white;
        $start: $wl + $priceH + $padL*2;
        top: $start;
        &--1 {
          top: $start + $rowH;
        } 
        &--2 {
          top: $start + $rowH*2;
        }

        &--3 {
          top: $start + $rowH*3 + $padL*2;
        }
        &--4 {
          top: $start + $rowH*4 + $padL*2;
        }
        &--5 {
          top: $start + $rowH*5 + $padL*2;
        }
        &--6 {
          top: $start + $rowH*6 + $padL*4;
        }
        &--7 {
          top: $start + $rowH*7 + $padL*4;
        }
        &--8 {
          top: $start + $rowH*8 + $padL*4;
        }
        &--9 {
          top: $start + $rowH*9 + $padL*6;
        }
        &--10 {
          top: $start + $rowH*10 + $padL*6;
        }
        // BOX PART -----------------------------------------------
        // BOX PART -----------------------------------------------
        // BOX PART -----------------------------------------------
        // BOX PART -----------------------------------------------
        .box-part {
          position: absolute;
          width: 100%;
          height: $rowH;
          &--left {
            left: 0;
            width: 50%;
          }
          &--right {
            right: 0;
            width: 50%;
          }
          &--border-right {
            border-left: 1px solid white;
            background: 0;
            width: 50%;
            right: 0;

          }

          p {
            margin: ($rowH - $priceFS + 8px)/2 0;
            background: black;
            line-height: $priceFS - 8px;
            font-size: $priceFS - 8px;

          }
          &--smallp {
            
            p {
              font-size: 12px;
            }
          }
          button {
            margin: 0;
            border: none;
            box-sizing: border-box;
            border-radius: 0;
            filter: drop-shadow(0 0 0 black);
            font-size: $rowH/2 - 8px;
            line-height: $rowH/2;
            height: $rowH;
            width: 100%;
            display: inline-block;
            font-family: 'Inter', sans-serif;
            text-transform: uppercase;
            font-weight: 600;
            &:hover {
              transform: translate(0);
            }
          }
          input {
            display: inline-block;
            margin: 0;
            border: none;
            box-sizing: border-box;
            line-height: $rowH/2;
            width: 100%;
            height: $rowH;
            background: black;
            color: gray;
            border-left: 1px solid white;
            font-family: 'Inter', sans-serif;
            &:focus {
              outline: none;
              color: white;
            }
          }

        }
      } // end box-row
      &--swapper {
        $rowH: 48px;
        border-top: 1px solid #fff;
        button {
            margin: 0;
            border: 1px solid white;
            box-sizing: border-box;
            border-radius: 0;
            filter: drop-shadow(0 0 0 black);
            font-size: $rowH/2 - 8px;
            line-height: $rowH/2;
            height: $rowH;
            width: 100%;
            display: inline-block;
            font-family: 'Inter', sans-serif;
            text-transform: uppercase;
            font-weight: 600;
            &:hover {
              transform: translate(0);
            }
          }
      }
    } // end box-sub
  }
}

.password {
  input {
    font-size: 32px;
    background: black;
    border: 1px solid white;
    color: white;
    display: block;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    margin-bottom: 16px;
    padding: 4px;
  }
  button {
    display: block;
  }
}


$chartColor: scale-color(scale-color(#ff8ae8, $lightness: -60%), $saturation: -50%);

$chartH: 320px;
$chartFS: 12px;
.chart {
  width: 100%;
  height: $chartH;
  //border-top: 1px dashed #fff;
  .portion {
    display: inline-block;
    background: $chartColor;
    height: $chartH;
    position: relative;
    &-1 {
      background: adjust-hue($chartColor, 90deg);
    }
    &-2 {
      background: adjust-hue($chartColor, 180deg);
    }
    &-3 {
      background: adjust-hue($chartColor, 275deg);
    }
    span {
      position: absolute;
      display: block;
      bottom: -$chartFS;
      left: 0;
      width: $chartH;
      text-align: center;
      transform-origin: top left;
      transform: rotateZ(-90deg) + translateY(4px);
      font-size: $chartFS;
      line-height: $chartFS;
      height: $chartFS; 
    }
  }
}


.foli {
  font-family: 'Inter', sans-serif;
  &--false {
    display: none;
  }
  top: 20vh;
  bottom: 20vh;
  overflow-y: scroll;
  padding: 32px;
  @include utils.scrolly;
  h3 {
    font-size: 40px;
    color: $yorange;
  }
  h4 {
    font-size: 32px;
    color: $rorange;
  }
  .val-el {
    p.value-top {
      font-size: 20px;
      margin-bottom: 0;
      & + p {
        margin-top: 0;
        color: #5e5e5e;
      }
    }

  }
  .foli-nfts, .foli-lps {
    h5 {
      font-size: 18px;
      margin-bottom: 0px;
    }
    p {
      margin-top: 4px;
      margin-bottom: 2px;
      &.footnote {
        font-size: 12px;
        color: #5e5e5e;
        margin-top: 0px;
        margin-bottom: 0px;
      }
    }

  }

  &__chart {
    height: 320px;
  }
  $rowH: 48px;
  button {
            margin: 0;
            border: 1px solid white;
            box-sizing: border-box;
            border-radius: 0;
            filter: drop-shadow(0 0 0 black);
            font-size: $rowH/2 - 8px;
            line-height: $rowH/2;
            height: $rowH;
            width: 100%;
            display: inline-block;
            font-family: 'Inter', sans-serif;
            text-transform: uppercase;
            font-weight: 600;
            &:hover {
              transform: translate(0);
            }
          }
}


$green: #83ff8f;
.toolbox {
  font-family: 'Inter', sans-serif;
  $wl: 256px;
  $priceH: 48px;
  $priceFS: 24px;
  $padL: 8px;
  position: absolute;
  top: 0;
  border: 1px solid #fff;
  height: $wl + $priceH;
  box-sizing: border-box;
  &--left {
    width: $wl;
    left: 0;
  
  }
  &--right {
    width: calc(100% - #{$wl});
    left: $wl;
    padding: $padL*2;

  }
  .toolbox__img {
    position: relative;
    width: $wl;
    height: $wl;
    box-sizing: border-box;
    img {
      width: $wl;
      height: $wl;
    }
    
    
  }
  
  .toolbox__price {
    position: relative;
    font-size: $priceFS;
    line-height: $priceFS;
    height: $priceH;
    border-top: 1px solid #fff;
    box-sizing: border-box;
    .token-name {
      position: absolute;
      top: ($priceH - $priceFS)/2;
      left: $padL;
    }
    .price {
      position: absolute;
      top: ($priceH - $priceFS)/2;
      right: $padL;
      color: $green;
    }
  }
  $rowH: 32px;
  $rowFS: 16px;
  .info-row {
    width: 100%;
    position: relative;

    height: $rowH;
    font-size: $rowFS;
    line-height: $rowFS;
    margin-top: 0;
    &__el {
      position: absolute;
      top: ($rowH - $rowFS)/2;
    }
    &__name {
      left: $padL;
      text-transform: uppercase;
    }
    &__amt {
      right: calc(38% - #{$padL});
      color: $green;
    }
    &__price {
      right: $padL;
      color: $green;
    }
  }

  .load-data {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border: none;
    box-sizing: border-box;
    margin: 0;
    border-radius: 0;
    filter: drop-shadow(0 0 0 black);
    border-top: 1px solid white;
    height: calc(#{$priceH} - 1.5px);
    font-size: $priceFS;
    line-height: $priceFS;
    font-family: 'Inter', sans-serif;
    text-transform: uppercase;
            font-weight: 600;
    &:hover {
      transform: translate(0);
    }
  }

  .claim-row {
    position: absolute;
    bottom: calc(#{$priceH} - 1.5px);
    left: 0;
    height: $priceH;
    width: 100%;
    text-align: left;
    border-top: 1px solid white;
    & + .claim-row {
      bottom: calc(#{$priceH}*2 - 1.5px);
      border-bottom: 1px solid white;
    }
    button {
      margin: 0;
      border: none;
      box-sizing: border-box;
      border-radius: 0;
      filter: drop-shadow(0 0 0 black);
      font-size: $priceFS;
      line-height: $priceFS;
      height: $priceH;
      width: 50%;
      display: inline-block;
      font-family: 'Inter', sans-serif;
      text-transform: uppercase;
            font-weight: 600;
      &:hover {
        transform: translate(0);
      }
    }
    input {
      display: inline-block;
      margin: 0;
      border: none;
      box-sizing: border-box;
      line-height: $priceFS;
      font-size: $priceFS - 8px;
      height: $priceH;
      background: black;
      color: gray;
      border-left: 1px solid white;
      vertical-align: top;
      font-family: 'Inter', sans-serif;
      &:focus {
        outline: none;
        color: white;
      }
    }
  }

}
.tools .tools__box button.load-data,
.tools .tools__box .claim-row button
 {
    margin: 0;
  }

.tools .tools__box .tools__box-sub .claim-row input {
  width: 50%;
}
.tools {
.tools__box-sub {
  &.toolbox-container {
    min-height: $minhTools + 256px;
    &--taller {
      min-height: $minhTools + 512px;
    }
    &--shorter {
      min-height: $minhTools - 96px;
      border-bottom: none;
    }
  }
}
}

.tools .tools__box .tools__box-sub .claim-row input {
  font-size: 16px;
}

$rowH: 32px;
  $rowFS: 20px;
  $padL: 8px;
  $col2: calc(26% - #{$padL});
  $col1: calc(48% - #{$padL});
  .info-row {
    width: 100%;
    position: relative;

    height: $rowH;
    font-size: $rowFS;
    line-height: $rowFS;
    margin-top:16px;
    &__el {
      position: absolute;
      top: ($rowH - $rowFS)/2;
    }
    &__name {
      left: $padL;
      text-transform: uppercase;
    }
    &__amt {
      right: $col2;
      color: $rorange;
    }
    &__price {
      right: $padL;
      color: $rorange;
    }
    &__pct {
      right: $col1;
      color: $green;
    }
    &--sub {
      margin-top:0;
      font-size: $rowFS*5/8;
      line-height: $rowFS*5/8;
      height: $rowFS*5/8 + $padL;
      .info-row__el {
        position: absolute;
        top: $padL/2;
      }
      .info-row__name {
        left: $padL;
        text-transform: uppercase;
      }
      .info-row__amt {
        right: $col2;
        color: $green;
      }
      .info-row__price {
        right: $padL;
        color: $green;
      }
      .info-row__pct {
        right: $col1;
        color: $green;
      }
    }

    &--header {
      margin-top:16px;
      font-size: $rowFS*5/8;
      line-height: $rowFS*5/8;
      height: $rowFS*5/8 + $padL;
      .info-row__el {
        position: absolute;
        top: $padL/2;
      }
      .info-row__name {
        left: $padL;
        text-transform: uppercase;
      }
      .info-row__amt {
        right: $col2;
        color: $green;
      }
      .info-row__price {
        right: $padL;
        color: $green;
      }
      .info-row__pct {
        right: $col1;
        color: $green;
      }
      border-bottom: 1px solid white;

    }

    

  }

  .val-el--CULT {
    .info-row-- {
      margin-top: 0;
    }
  }

  @include bf.BF;
  .swapper-box {
    padding: 16px;
    
    margin: 16px 0;
    font-family: 'Inter', sans-serif;
    .swapper-p {
      font-size: 12px;
      margin: 8px 0;
      &.sp {
        margin-top: 0px;
        font-size: 16px; 
        font-weight: 200;
      }
    }
  }
  .swapper-inputs {
    margin: 16px 0;
    input {
      border: none;
      background: black;
      color: white;
      margin: 0 8px;
      border-bottom: 1px solid $red;
      font-family: 'Inter', sans-serif;
      font-weight: 100;
      
      &:focus {
        outline: none;
        color: $yellow;
      }
      &.swapper-amt {
        border-top: 1px solid $red;
        border-bottom: 1px solid $yellow;

      }
      &.swapper-name--out {
        border-bottom: 1px solid $yellow;
      }
    }
  }
  .tools .tools__box .tools__box-sub .swapper-inputs input {
    font-size: 32px;
    width: 128px;

  }
 .routes {
  border-left: 1px solid $yellow;
  border-bottom: 1px solid $yellow;
  padding: 16px 0 0 0;
  text-align: right;
 }
 .route {
  border-top: 2px solid $yellow;
  position: relative;
  margin: 8px 0;
  cursor: pointer;
  transition: 0.2s background ease-in-out, 0.2s color ease-in-out;
  &:hover {
    color: black;
    background: $yellow;
    .route-el--last-false {
      filter: drop-shadow(0 0 4px rgba(0,0,0,0.4));
      transform: scale(1.1);
    }
  }
  span.abs {
    position: absolute;
    display: inline-block;
    font-size: 10px;
    color: gray;
    bottom: 4px;
    left: 16px;
  }
 }
 .route-el{
  
  display: inline-block;
  margin-right: 8px;
  font-size: 12px;
  line-height: 40px;
  height: 40px;
  font-weight: 600;
  strong {
    font-family: 'Inter', sans-serif;
    text-transform: uppercase;
    font-weight: 100;
  }
  &--0 {
    font-size: 16px;
  }
  &--last-false {
    transition: 0.2s filter ease-in-out, 0.2s transform ease-in-out;
    font-size: 20px;
    border-bottom: 1px solid $yellow;
    filter: drop-shadow(0 0 4px rgba(0,0,0,0));
    transform: scale(1);
    padding: 0 8px;
  }



 }

p {
  color: white;
  font-size: 18px;
}


.a2Manager, .a2Info {
  max-width: 1000px;
  ul {
    color: white;
  }
}

.App {
  padding: 16px;

}
input {
  border: none;
  border-bottom: 1px solid white;
  background: none;
  color: white;
  font-size: 18px;
  line-height: 24px;
  &:focus {
    outline: none;
    border-bottom: 1px solid $rorange;
  }
}
h1, h2 {color: white;}

.points {
  display: inline-block;
  border: 1px solid white;
  border-radius: 4px;
  margin: 8px;
  padding: 8px;
  text-align: center;
}

.a2-score {
  border-bottom: 1px dashed white;
  padding-bottom: 16px;
}