@mixin Controls {
	button.btn-controls {
  		position: fixed;
  		bottom: 16px;
  		right: 16px;
	}
	.controls {
		position: fixed;
		top: 100vh;
		right: 16px;
		transition: 0.2s top ease-in-out;

		&.controls-open--true {
			top: calc(100vh - 420px); 
		}
	}


	.control {

	}
}