@mixin Inv {
	$w: 256px;
	$imgw: $w/6;
	.inventory {
		font-family: 'Inter', sans-serif;
		position: fixed;
		left: -$w;
		width: $w;
		bottom: 16px + 16px + 32px;
		overflow: hidden;
		height: $imgw*6 + 16px*6;
		transition: 0.2s left ease-in-out;
		color: white;
		background: rgba(0,0,0,0.8);
		border-top-right-radius: 0px;
		&.inventory-open--true {
			left: 16px;
		}

		&--1155 {
			height: $imgw*8 + 16px*8;
		}
	}
	.inv-item {
		box-sizing: border-box;
		
		padding: 8px;
		&--nft {

		}
		&--token {

		}
	}
	img.nft-thumb {
		height: $imgw;
		width: $imgw;
		vertical-align: middle; 
	}
	img.token-icon {
		height: $imgw;
		width: $imgw;
		border-radius: $imgw;
		overflow: hidden;
		vertical-align: middle;
		filter: drop-shadow(0 0 4px white); 
	}
	.inv-bal {
		display: inline-block;
		vertical-align: middle; 
		margin-left: 16px;

		span {
			color: #6e6e6e;
		}
	}


	.inventory--1155 {
		$nw: 96px;
		$g: 8px;
		$nh: $nw;
		padding: $g;
		box-sizing: border-box;

		.inv-item {
			display: inline-block;
			position: relative;
			width: $nw;
			height: $nh;
			border: 1px solid white;
			box-shadow: inset 0 0 $g rgba(255,255,255,0.6);
			background: black;
			margin: $g/2;
			.nft-thumb {
				position: absolute;
				top: $g;
				left: $g;
				width: $nw - 2*$g;
				height: $nh - 2*$g;

			}
			.inv-bal {
				position: absolute;
				bottom: $g;
				right: $g;
				background: rgba(255,255,255,0.8);
				color: black;
				padding: 0 $g/2;
				border-radius: 2px;
				
				font-size: 20px;
				line-height: 22px;
				span {
					display: none;
				}
			}
		}
	}
}