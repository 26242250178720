@use "src/_utils.scss";
$red: #FF4A4A;
$rorange: #FF6A4D;
$orange: #FF9052;
$yorange: #FFB655;
$yellow: #FFD757;
$pad: 12px;
$imgH: 48px;
$poolH: $imgH + 2*$pad; 
$fs: 12px;
$fs2: 16px;
	    	$bpad: 6px;
@mixin BF {
	.burn-farms {
		overflow-y: scroll;
		top: 10vh;
		bottom: 10vh;
		left: 15vw;
		right: 15vw;
		@include utils.scrolly;
	    &--false {
	      display: none;
	    }



	    .bf-btn {
	    	font-size: 16px;
			padding: 6px 12px;
			font-family: 'Inter', sans-serif;
			text-transform: uppercase;
			font-weight: 600;
			border-radius: 0;
	    	&--true {
	    		background: $rorange;
	    	}
	    	& + .bf-btn {
	    		margin-left: 8px;
	    	}
	    	img {
	    		height: 20px;
	    		width: 20px;
	    		vertical-align: top;
	    		margin-right: 4px;
	    	}
	    }
	    font-family: 'Inter', sans-serif;
	    h3 {
	      font-size: 40px;
	      margin: 16px auto 8px;
	      //border-bottom: 1px dashed white;
	      padding-bottom: 0;
	      color: $yorange;
	    }
	    p {
	    	margin: 8px auto 12px;
	    	&.p-sep {
			    border-top: 1px dashed white;
			    padding-top: 16px;
			    margin-top: 16px;
			  }
	    }

	    
	    // COMPONENTS ---------------------------------------------------------

	    &__nav {
	    	margin-bottom: 24px;
	    }
	    &__header {
	    	position: relative;
	    	height: $fs;
	    	font-size: $fs;
	    	line-height: $fs;
	    	width: 100%;
	    	border-bottom: 1px solid white;
	    	
	    }
	    .col {
	    		position: absolute;
	    		&--0 {
	    			left: $pad;
	    		}
	    		&--1 {
	    			right: calc(100% - #{$imgH}*4 - #{$pad}*2);
	    		}
	    		&--2 {
	    			right: calc(100% - #{$imgH}*6 - #{$pad}*4);
	    		}
	    		&--3 {
	    			right: calc(100% - #{$imgH}*8 - #{$pad}*6);
	    		}
	    		&--has6 {
	    			right: calc(100% - #{$imgH}*10 - #{$pad}*8);
	    		}
	    		&--4 {
	    			right: $pad;
	    		}
	    		
	    	}
	    &.burn-farms-burn--true {
	    	.col {
	    		
	    		
	    		
	    	}
	    	.burn-farms__nav {
		    	margin-bottom: 8px;
		    	&--last {
		    		margin-bottom: 24px;
		    	}
		    }
		    .global-row {
		    	
		    	input {
		    		display: inline-block;
					margin: 0;
					border: none;
					box-sizing: border-box;
					line-height: $fs2;
					font-size: $fs2;
					height: $imgH;
					background: black;
					color: gray;
					border: 1px solid white;
					vertical-align: top;
					font-family: 'Inter', sans-serif;
					text-align: center;
					max-width: 2*$imgH;
					margin-right: 16px;
					&:focus {
						outline: none;
						color: white;
					}
		    	}
		    	button {
		    		border-radius: 0;
	    			font-size: $fs2;
	    			padding: $bpad $bpad*2;
	    			font-family: 'Inter', sans-serif;
	    			text-transform: uppercase;
	    			font-weight: 600;
	    			height: $imgH;
		    	}
		    	.burn-result {
		    		display: inline-block;
		    		width: 128px;
		    		border-bottom: 2px solid white;
		    		height: $imgH;
		    		box-sizing: border-box;
		    		vertical-align: top;
		    		line-height: $imgH;
		    		margin: 0 16px;

		    	}

		    }
	    }
	    .burn-pool {
	    	height: $poolH;
	    	transition: 0.2s height ease-in-out;
	    	overflow: hidden;
	    	&-expanded--true {
	    		height: $poolH*2;
	    	}
	    	padding: $pad;
	    	box-sizing: border-box;
	    	position: relative;
	    	width: 100%;
	    	&__icon {
	    		top: $pad;
	    		.icon-part {
	    			height: $imgH;
	    			width: $imgH;
	    			border-radius: $imgH;
	    			overflow: hidden;
	    			display: inline-block;
	    			filter: drop-shadow(0 0 4px $yellow);
	    			& + .icon-part {
	    				transform: translateX(-$imgH/4);
	    			}
	    		}
	    		img {
	    			height: $imgH;
	    			width: $imgH;
	    		}
	    	}

	    	
	    	&__yourstake, &__yourharvest {
	    		font-size: $fs2;
	    		line-height: $fs2;
	    		top: ($poolH - $fs2)/2;
	    	}

	    	&__stake, &__harvest {
	    		top: ($poolH - $fs2 - $bpad*2 - 6px)/2;
	    		font-size: $fs2;
	    		line-height: $fs2 + $bpad*2 + 6px; 
	    		button {
	    			border-radius: 0;
	    			font-size: $fs2;
	    			padding: $bpad $bpad*2;
	    			font-family: 'Inter', sans-serif;
	    			text-transform: uppercase;
	    			font-weight: 600;
	    		}
	    	}
	    }
	}

	.burn-farms .bf-restrict-deposit,
	.burn-farms .bf-restrict-withdraw,
	.burn-farms .bf-restrict-burn,
	.burn-farms .bf-restrict-info, {
		display: none;    	
	}

	.burn-farms-deposit--true .bf-restrict-deposit,
	.burn-farms-withdraw--true .bf-restrict-withdraw,
	.burn-farms-burn--true .bf-restrict-burn,
	.burn-farms-info--true .bf-restrict-info  {
		display: block;
	}


	.collapsible {
		display: none;
		position: absolute;
		top: $poolH;
		height: $poolH;
		padding: ($poolH - $imgH)/2 0;
		box-sizing: border-box;
		transform: scaleY(0);
		width: 100%;
		border-top: 1px dashed white;
		border-bottom: 1px solid white;
		text-align: left;
		&--true {
			display: block;
			transform: scaleY(1);
		}
		&__inside {
			display: inline-block;
			width: calc(#{$imgH}*3 + 8px);

			height: $imgH;
			margin-right: 16px;
			vertical-align: middle;
			.icon {
				height: $imgH;
					width: $imgH;
					border-radius: $imgH;
					overflow: hidden;
					display: inline-block;
				filter: drop-shadow(0 0 4px $yellow);
				img {
					height: $imgH;
					width: $imgH;
					

				}
				margin-right: 8px;
			}
			
			input, .make-lp-token-b {
				display: inline-block;
				margin: 0;
				border: none;
				box-sizing: border-box;
				line-height: $fs2;
				font-size: $fs2;
				height: $imgH;
				background: black;
				color: gray;
				border: 1px solid white;
				vertical-align: top;
				font-family: 'Inter', sans-serif;
				text-align: center;
				max-width: 2*$imgH;
				&:focus {
					outline: none;
					color: white;
				}
			}
			.make-lp-token-b {
				width: 2*$imgH;
				color: white;
				border-top: none;
				border-left: none;
				border-right: none;
			}

			&--double {
				width: calc(100% - 2*(#{$imgH}*3 + 32px));
				position: relative;
				input {
					width: 100%;
					max-width: 100%;
				}
				.max-input {
					position: absolute;
					width: 40px;
					height: $fs2 + 4px;
					padding: 2px 4px;
					box-sizing: border-box;
					right: 8px;
					top: ($imgH - $fs2 - 4px)/2;
					border-radius: 0;
	    			font-size: $fs;
	    			
	    			font-family: 'Inter', sans-serif;
	    			text-transform: uppercase;
	    			font-weight: 600;
	    			
				}
			}

			&--half {
				width: calc(50% - 16px);
				margin-right: 16px;
		
				position: relative;
				& + & {
					margin-right: 0;
				}
				input {
					width: 100%;
					max-width: 100%;
				}
				.max-input {
					position: absolute;
					width: 40px;
					height: $fs2 + 4px;
					padding: 2px 4px;
					box-sizing: border-box;
					right: 8px;
					top: ($imgH - $fs2 - 4px)/2;
					border-radius: 0;
	    			font-size: $fs;
	    			
	    			font-family: 'Inter', sans-serif;
	    			text-transform: uppercase;
	    			font-weight: 600;
	    			
				}
			}
			&-quad {
				position: relative;
				width: calc(25% - 16px);
				input, .make-lp-token-b {
					display: inline-block;
					margin: 0;
					border: none;
					box-sizing: border-box;
					line-height: $fs2;
					font-size: $fs2;
					height: $imgH;
					background: black;
					color: gray;
					border: 1px solid white;
					vertical-align: top;
					font-family: 'Inter', sans-serif;
					text-align: center;
					max-width: calc(100% - #{$imgH} - 8px);
					&:focus {
						outline: none;
						color: white;
					}
				}
				.make-lp-token-b {
					width: calc(100% - #{$imgH} - 8px);
					color: white;
				
					border-top: none;
					border-left: none;
					border-right: none;
					line-height: $imgH; 
				}
				.max-input {
					position: absolute;
					width: 40px;
					height: $fs2 + 4px;
					padding: 2px 4px;
					box-sizing: border-box;
					right: 8px;
					top: ($imgH - $fs2 - 4px)/2;
					border-radius: 0;
	    			font-size: $fs;
	    			
	    			font-family: 'Inter', sans-serif;
	    			text-transform: uppercase;
	    			font-weight: 600;
	    			
				}
				input.no-icon {
					width: calc(100%);
					max-width: calc(100%);
				}
				.break-lp-token {
					display: inline-block;
					margin: 0;
					border: none;
					box-sizing: border-box;
					
					font-size: $fs2;
					height: $imgH;
					background: black;
					color: gray;
					border-bottom: 1px solid white;
					vertical-align: top;
					font-family: 'Inter', sans-serif;
					text-align: center;
					width: calc(50% - #{$imgH} - 8px);
					color: white;
				
					border-top: none;
					border-left: none;
					border-right: none;
					line-height: $imgH; 
				}
				.burn-effect {
					font-size: 12px;
				}
			}
			&--final {
				margin-right: 0;
			}
			button {
				width: calc(100% - 8px);
				border-radius: 0;
    			font-size: $fs;
    			padding: $bpad $bpad*2;
    			font-family: 'Inter', sans-serif;
    			text-transform: uppercase;
    			font-weight: 600;
    			height: $imgH;
			}
		}
	}

}